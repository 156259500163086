import React, { useCallback, useEffect, useState } from "react";
import { CASE_API } from "../../apis/api";
import { Card, Form, Image, Table, Typography } from "antd";
import { Link } from "react-router-dom";
const Cases = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({});
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const columns = [
    {
      title: "Container number",
      dataIndex: "container_number",
      key: "container_number",
      render: (text, row) => (
        <Link to={`/container-management/containers/${row.container_id.id}`}>
          <Typography.Link>{row.container_id.container_number}</Typography.Link>
        </Link>
      ),
    },
    {
      title: "CFS Name",
      dataIndex: "cfs_name",
      key: "cfs_name",
    },
    {
      title: "BE No & Date",
      dataIndex: "be_no_date",
      key: "be_no_date",
    },
    {
      title: "Importer Name",
      dataIndex: "importer_name",
      key: "importer_name",
    },
    {
      title: "Date of examination",
      dataIndex: "date_of_examination",
      key: "date_of_examination",
    },
    {
      title: "Images",
      dataIndex: "images",
      key: "images",
      render: (text, row) => (
        <Image.PreviewGroup items={row.images}>
          <Image width={75} title="View" src={row.images[0]} />
        </Image.PreviewGroup>
      ),
    },
  ];

  const getCases = useCallback(() => {
    setLoading(true);
    CASE_API.getCases(params)
      .then((response) => {
        setData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  useEffect(() => {
    getCases();
  }, [getCases, params]);
  return (
    <Card bodyStyle={{ padding: 0 }} title="Cases">
      <Table
        dataSource={data.results}
        columns={columns}
        loading={loading}
        rowKey="id"
        pagination={{
          current: data.page,
          pageSize: data.limit,
          total: data.totalResults,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
          onChange: (value) => {
            console.log("value", value);
            setParams({ ...params, page: value });
          },
        }}
      />
    </Card>
  );
};

export default Cases;
