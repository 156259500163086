import axios from "./axios";

const CONTAINER_API = {
  getContainers: (params) =>
    axios({
      url: `/containers`,
      method: "GET",
      params,
    }),

  searchContainers: (params) =>
    axios({
      url: `/containers/search-by-ids`,
      method: "GET",
      params,
    }),

  getContainer: (id) =>
    axios({
      url: `/containers/details/${id}`,
      method: "GET",
    }),

  createContainers: (data) =>
    axios({
      url: `/containers`,
      method: "POST",
      data,
    }),

  uploadContainers: (data) =>
    axios({
      url: `/containers/upload`,
      method: "POST",
      data,
    }),

  checkStatus: (data) =>
    axios({
      url: `/containers/check-status`,
      method: "POST",
      data,
    }),

  searchbyDesc: (value) =>
    axios({
      url: `/containers/search-by-description`,
      method: "GET",
      params: { text: value },
    }),
};

export default CONTAINER_API;
