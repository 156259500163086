import {
  Card,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Upload,
  Button,
  Table,
  Space,
  Spin,
  message,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import * as xlsx from "xlsx";
import dayjs from "dayjs";
import CONTAINER_API from "../../apis/containerAPI";
import { arrayToFormData, jsonToFormData } from "../../utils";

const UploadContainer = () => {
  const [uploadForm] = useForm();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const imageprops = {
    onRemove: (file) => {
      setFileList([]);
      setExcelData([]);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    onChange: (e) => {
      if (e.file) {
        const file = e.file;
        if (file && file.status !== "removed") {
          setLoading(true);
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = xlsx.utils.sheet_to_json(worksheet);
            console.log(json);
            setExcelData(json);
          };
          setTimeout(() => {
            reader.readAsArrayBuffer(e.file);
            setLoading(false);
          }, 2000);
        }
      }
    },
    fileList,
  };

  const upload = async (values) => {
    setSubmitting(true);

    const igm_number = values.igm_number;
    const igm_date =
      values.igm_date && dayjs(values.igm_date).format("YYYY-M-D");

    const data = await excelData.map((v) => ({
      ...v,
      igm_number: igm_number,
      igm_date: igm_date,
    }));

    const formData = arrayToFormData(data);
    fileList.forEach((file) => {
      formData.append("files", file);
    });

    CONTAINER_API.uploadContainers(formData)
      .then((response) => {
        message.success(`${excelData.length} containers created successfully`);
        setFileList([]);
        uploadForm.resetFields();
        setSubmitting(false);
        setExcelData([]);
      })
      .catch((error) => {
        message.error("Something went wrong");
      });
  };
  return (
    <Space style={{ width: "100%" }} direction="vertical" size="middle">
      {loading && (
        <Spin
          spinning={loading}
          fullscreen
          tip="Processing excel file"
          size="large"
        />
      )}
      <Card title="Upload Container excel">
        <Form form={uploadForm} layout="vertical" onFinish={upload}>
          <Row gutter={18}>
            <Col sm={12}>
              <Form.Item
                label="IGM No"
                name="igm_number"
                rules={[
                  { required: true, message: "Please enter igm number" },
                  { max: 8, message: "Maximum 8 charecters allowed" },
                ]}
              >
                <Input placeholder="IGM Number" />
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item
                label="IGM Date"
                name="igm_date"
                rules={[{ required: true, message: "Please enter igm date" }]}
              >
                <DatePicker style={{ width: "100%" }} placeholder="IGM Date" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label="Excel File"
            help="The excel file must have container_number , description & cfs_name field"
          >
            <Upload.Dragger
              {...imageprops}
              accept=".xlsx, .xls, .csv"
              maxCount={1}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag excel or csv file to this area to upload
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item>
            <Button
              block
              htmlType="submit"
              type="primary"
              disabled={fileList.length === 0 ? true : false}
              loading={submitting}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {excelData.length > 0 && (
        <Table
          dataSource={excelData}
          columns={[
            {
              title: "Container Number",
              dataIndex: "container_number",
              key: "container_number",
            },
            {
              title: "CFS Name",
              dataIndex: "cfs_name",
              key: "cfs_name",
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
            },
          ]}
          pagination={false}
        />
      )}
    </Space>
  );
};

export default UploadContainer;
