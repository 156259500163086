import { Column } from "@ant-design/plots";
import React, { useEffect } from "react";
import { REPORT_API } from "../../apis/api";
import { Card } from "antd";

const MonthlyScannedReport = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getData = () => {
    REPORT_API.getMonthlyScanningReport().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const config = {
    data,
    xField: "month_name",
    yField: "total_number",
    style: {
      fill: ({ type }) => {
        return "#2989FF";
      },
    },
    legend: false,
  };

  return (
    <Card
      loading={loading}
      title={`Month wise scanning report (${new Date().getFullYear()})`}
    >
      <Column {...config} />
    </Card>
  );
};

export default MonthlyScannedReport;
