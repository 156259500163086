import React, { useCallback, useEffect, useState } from "react";
import IGM_API from "../../apis/igmAPI";
import { Card, Table } from "antd";
import moment from "moment";

const columns = [
  {
    title: "Igm Number",
    dataIndex: "igm_number",
    key: "igm_number",
  },
  {
    title: "Igm Date",
    dataIndex: "igm_date",
    key: "igm_date",
    render: (text, row) => moment(row.igm_date).format("DD-MM-YYYY"),
  },
  // {
  //   title: "Date & Time",
  //   dataIndex: "date_time",
  //   key: "date_time",
  //   render: (text, row) => moment(row.igm_date).format("DD-MM-YYYY HH:mm:ss"),
  // },
  {
    title: "Number of Container",
    dataIndex: "container_count",
    key: "container_count",
  },
];
const IGMLogs = () => {
  const [igm, setIgm] = useState({});
  const [params, setParams] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const getIgmLogs = useCallback(() => {
    setLoading(true);
    IGM_API.getIgmLogs(params)
      .then((response) => {
        setIgm(response.data);
        setError(false);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  useEffect(() => {
    getIgmLogs();
  }, [getIgmLogs, params]);

  return (
    <>
      <Card title="IGM Logs" bodyStyle={{ padding: 0 }}>
        <Table
          columns={columns}
          loading={loading}
          dataSource={igm.results}
          pagination={{
            current: igm.page,
            pageSize: igm.limit,
            total: igm.totalResults,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: (value) => {
              console.log("value", value);
              setParams({ ...params, page: value });
            },
          }}
        />
      </Card>
    </>
  );
};

export default IGMLogs;
