import { Row, Col, Typography } from "antd";

const PrivacyPolicy = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={24}
          style={{
            background: "#F5F5F5",
            padding: "50px 30px",
            minHeight: "87vh",
          }}
        >
          <h1>Privacy Policy</h1>
          <Typography.Paragraph>
            This Privacy Policy applies to the CSD Portal & Mobile APP (
            csd.jnpa.in )
          </Typography.Paragraph>
          <Typography.Paragraph>
            CSD Mobile App (csd.jnpa.in) recognises the importance of
            maintaining your privacy. We value your privacy and appreciate your
            trust in us. This Policy describes how we treat user information we
            collect on www.csd.jnpa.in and other offline sources. This Privacy
            Policy applies to current and former visitors to our website and to
            our online users. By visiting and/or using our website, you agree to
            this Privacy Policy.
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Title level={5}>
              Information We collect
            </Typography.Title>
            <Typography.Paragraph>
              The APP is used for Internal organisational purpose of Jawahar
              Customs. Official members with allocated login credentials are
              allowed to sign in and use the app.
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text strong> Contact information:</Typography.Text> We
              may collect your name, email, mobile number, phone number, street,
              city, state, pincode, country and ip address.
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Text strong>Other information:</Typography.Text> If
              you use our website, we may collect information about your IP
              address and the browser you're using. We may look at what site you
              came from, duration of time spent on our website, pages accessed
              or what site you visit when you leave us. We may also collect the
              type of mobile device you are using, or the version of the
              operating system your computer or device is running.
            </Typography.Paragraph>
            <Typography.Paragraph>
              We collect information directly from you when you register and use
              the app to share official reports through the app.
            </Typography.Paragraph>

            <Typography.Paragraph>
              <Typography.Title level={5}>
                Use of your personal information
              </Typography.Title>
              We use information to improve our products and services. We may
              use your information to customize your experience with us. This
              could include displaying content based upon your preferences.
            </Typography.Paragraph>
            <Typography.Paragraph>
              We use information to send you transactional communications. We
              may send you emails or SMS about your account or a ticket created.
            </Typography.Paragraph>
            <Typography.Paragraph>
              We use information as otherwise permitted by law.
            </Typography.Paragraph>
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Title level={5}>
              Sharing of information with third-parties
            </Typography.Title>
            <Typography.Paragraph>
              We don't share your information with any third party.
            </Typography.Paragraph>
          </Typography.Paragraph>

          <Typography.Paragraph>
            <Typography.Title level={5}>Grievance Officer</Typography.Title>
            <Typography.Paragraph>
              In accordance with Information Technology Act 2000 and rules made
              there under, the name and contact details of the Grievance Officer
              are provided below:
            </Typography.Paragraph>
            <Typography.Paragraph>
              <Typography.Paragraph strong>
                Mr.Dharmvir Singh
              </Typography.Paragraph>
              <Typography.Paragraph strong>
                Superintendent CSD Section
              </Typography.Paragraph>
              <Typography.Paragraph strong>
                Jawaharlal Nehru Custom House Mumbai
              </Typography.Paragraph>
              <br />
              <Typography.Paragraph strong>
                {" "}
                Customs Zone 2
              </Typography.Paragraph>
              <Typography.Paragraph>
                If you have any questions about this Policy or other privacy
                concerns, you can also email us at csd.jnch@gov.in
              </Typography.Paragraph>
            </Typography.Paragraph>
            <Typography.Title level={5}>
              Updates to this policy
            </Typography.Title>
            This Privacy Policy was last updated on 26th Jul 2024. From time to
            time we may change our privacy practices. We will notify you of any
            material changes to this policy as required by law
          </Typography.Paragraph>
          <Typography.Paragraph>
            <Typography.Title level={5}>Jurisdiction</Typography.Title>
            If you choose to visit the website, your visit and any dispute over
            privacy is subject to this Policy and the website's terms of use. In
            addition to the foregoing, any disputes arising under this Policy
            shall be governed by the laws of India.
          </Typography.Paragraph>
        </Col>
      </Row>
    </div>
  );
};
export default PrivacyPolicy;
