import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Card,
  Table,
  Space,
  Typography,
  Form,
  Drawer,
  Select,
  Spin,
  Input,
  Button,
  message,
  Row,
  Col,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import SCANNEDIMG_API from "../../apis/scannedImgAPI";
import debounce from "lodash/debounce";
import Cookies from "js-cookie";
import PermissionsGate from "../../services/RBAC/permissionGate";
import { CORE_API } from "../../apis/api";

const NotScannedImage = () => {
  const [data, setData] = useState({});
  const [reason, setReason] = useState(null);
  const [params, setParams] = useState({});
  const [containerId, setContainerId] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [scanners, setScanners] = useState([]);
  const [form] = Form.useForm();

  const getScanners = () => {
    CORE_API.getCoreData("scanners").then((response) => {
      setScanners(response.data);
    });
  };

  const getScannedImage = useCallback(() => {
    setLoading(true);
    const newParams = Object.assign(params, {
      is_not_scanned: true,
    });
    SCANNEDIMG_API.getScannedImage(newParams)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const save = (values) => {
    if (!containerId) {
      message.error("Please select a container");
      return;
    }
    values.is_not_scanned = true;
    values.reason =
      values.reason !== "other" ? values.reason : values.other_reson;
    values.container_number = containerId;
    SCANNEDIMG_API.createScannedImage(values)
      .then((response) => {
        form.resetFields();
        setOpen(false);
        getScannedImage();
      })
      .catch((error) => {
        message.error("Something went wrong");
      });
    setSubmitting(false);
    message.success("Scanned images uploaded sucessfully");

    form.resetFields();
    getScannedImage();
    setOpen(false);
  };

  const columns = [
    {
      title: "Container number",
      dataIndex: "container_number",
      key: "container_number",
      render: (text, row) =>
        row.container_number ? (
          <Link
            to={`/container-management/containers/${row?.container_number?._id}`}
          >
            <Typography.Link>
              {row?.container_number?.container_number || "N/a"}
            </Typography.Link>
          </Link>
        ) : (
          "N/a"
        ),
    },

    {
      title: "IGM Number",
      dataIndex: "igm_number",
      key: "igm_number",
      render: (text, row) => row?.container_number?.igm_number || "N/a",
    },
    {
      title: "Scanner type",
      dataIndex: "scanner_type",
      key: "scanner_type",
    },

    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      render: (text, row) => row?.shift,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },

    {
      title: "Uploaded by",
      dataIndex: "created_by",
      key: "created_by",
      render: (text, row) => (
        <span>
          {text?.name} ({text?.employee_id})
        </span>
      ),
    },
  ];

  useEffect(() => {
    getScannedImage();
  }, [getScannedImage, params]);

  useEffect(() => {
    getScanners();
  }, []);

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Card
          title="Not Scanned Images"
          bodyStyle={{ padding: 0 }}
          extra={
            <PermissionsGate scopes={["create-scanned-images"]}>
              <Button type="default" onClick={() => setOpen(true)}>
                Add
              </Button>
            </PermissionsGate>
          }
        >
          <Table
            dataSource={data.results}
            columns={columns}
            loading={loading}
            rowKey="id"
            pagination={{
              current: data.page,
              pageSize: data.limit,
              total: data.totalresults,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              onChange: (value) => {
                console.log("value", value);
                setParams({ ...params, page: value });
              },
            }}
          />
        </Card>
      </Space>
      <Drawer
        title="Upload Not Scanned Entries"
        maskClosable={false}
        width={600}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Form form={form} layout="vertical" onFinish={save}>
          <Form.Item label="Container number with IGM number">
            <ContainerAutoComplete
              fetchOptions={fetchData}
              setValue={setContainerId}
            />
          </Form.Item>
          <Row gutter={[32, 8]}>
            <Col sm={12}>
              <Form.Item
                name="shift"
                label="Shift"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Shift"
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    console.log("value", value);
                  }}
                >
                  <Select.Option value="day"> Day</Select.Option>
                  <Select.Option value="night"> Night</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col sm={12}>
              <Form.Item name="scanning_date" label="Scanning Date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col sm={24}>
              <Form.Item
                name="scanner_type"
                label="Scanner Type"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Scanner type"
                  onChange={(value) => {
                    console.log("value", value);
                  }}
                >
                  {scanners.map((scanner) => (
                    <Select.Option
                      key={scanner._id}
                      value={`${scanner.name}(${scanner.code})`}
                    >
                      {scanner.name} ({scanner.code})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col sm={24}>
              <Form.Item
                name="reason"
                label="Reason"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="select reason"
                  onChange={(value) => {
                    setReason(value);
                  }}
                >
                  <Select.Option value="Over weight">Over weight</Select.Option>
                  <Select.Option value="Over dimension">
                    Over dimension
                  </Select.Option>
                  <Select.Option value="Fixed scanner not working">
                    Fixed scanner not working{" "}
                  </Select.Option>
                  <Select.Option value="Mobile scanner not working">
                    Mobile scanner not working{" "}
                  </Select.Option>
                  <Select.Option value="Exempted from scanning">
                    Exempted from scanning{" "}
                  </Select.Option>
                  <Select.Option value="other">Other</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {reason === "other" && (
            <Form.Item label="Other Reason" name="other_reson">
              <Input.TextArea placeholder="Please specify other reason" />
            </Form.Item>
          )}
          <Button type="primary" htmlType="submit" block loading={submitting}>
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export const ContainerAutoComplete = ({
  fetchOptions,
  debounceTimeout = 300,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      onChange={(value) => props.setValue(value)}
    />
  );
};

const fetchData = async (keyword) => {
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/containers/search?keyword=${keyword}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((body) => {
      return body.map((item) => ({
        label: (
          <p>
            <Typography.Text mark>{item.cfs_name}</Typography.Text>
            {"  "}
            <Typography.Text>
              {item.container_number} - {item.igm_number}
            </Typography.Text>
          </p>
        ),
        value: item.id,
      }));
    });
};

export default NotScannedImage;
