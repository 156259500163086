import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { CORE_API } from "../../apis/api";
import PermissionsGate from "../../services/RBAC/permissionGate";
const Roles = () => {
  const [data, setData] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const getPermissions = () => {
    CORE_API.getCoreData("permissions").then((res) => {
      setPermissions(res.data);
    });
  };

  const getRoles = () => {
    CORE_API.getRoles().then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getRoles();
    getPermissions();
  }, []);

  const columns = [
    {
      title: "Role name",
      dataIndex: "name",
      key: "name",
      width: 200,
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      render: (text, row) =>
        row.permissions.length < 1 ? (
          <Tag color="orange">No permission added</Tag>
        ) : (
          row.permissions.map((item) => (
            <Tag style={{ marginTop: 10 }} color="blue">
              {item.name}
            </Tag>
          ))
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, row) => (
        <Space size="middle">
          <PermissionsGate
            scopes={["update-role"]}
            errorProps={{ disabled: true }}
          >
            <Button
              type="primary"
              ghost
              onClick={() => {
                setEditRow(row);
                form.setFieldsValue({
                  name: row.name,
                  permissions: row.permissions.map((item) => item.id),
                });
              }}
            >
              Edit
            </Button>
          </PermissionsGate>
        </Space>
      ),
    },
  ];

  const submit = (values) => {
    values.permissions = values.permissions.toString();
    setLoading(true);
    CORE_API.createRole(values).then((res) => {
      getRoles();
      form.resetFields();
      message.success("Role created successfully");
      setLoading(false);
    });
  };

  const update = (values) => {
    values.permissions = values.permissions.toString();
    setLoading(true);
    CORE_API.updateRole(values, editRow.id).then((res) => {
      form.resetFields();
      setEditRow(null);
      message.success("Role updated successfully");
      setLoading(false);
      getRoles();
    });
  };

  return (
    <Space size="middle" style={{ width: "100%" }} direction="vertical">
      <Card size="small" title="Create role">
        <Form
          form={form}
          layout="vertical"
          onFinish={editRow?.id ? update : submit}
        >
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input placeholder="role name" />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                label="Permissions"
                name="permissions"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="select atleast one permission"
                  mode="multiple"
                >
                  {permissions.map((item) => (
                    <Select.Option key={item.id}>{item.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {editRow?.id ? (
            <Row gutter={32}>
              <Col sm={12}>
                <Button
                  type="primary"
                  danger
                  loading={loading}
                  block
                  onClick={() => {
                    setEditRow(null);
                    form.resetFields();
                  }}
                >
                  Cancel
                </Button>
              </Col>

              <Col sm={12}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  Update
                </Button>
              </Col>
            </Row>
          ) : (
            <Button type="primary" block htmlType="submit" loading={loading}>
              Create
            </Button>
          )}
        </Form>
      </Card>
      <Card title="Roles" bodyStyle={{ padding: 0 }}>
        <Table
          pagination={false}
          columns={columns}
          dataSource={data}
          rowKey="id"
        />
      </Card>
    </Space>
  );
};

export default Roles;
