import { Button, Card, Col, Image, Row, Space, Table, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { ChapeterHeadingList } from "../containers";
import EXAMINEDIMG_API from "../../apis/examinedImgAPI";
import moment from "moment";

const columns = [
  {
    title: "Images",
    dataIndex: "images",
    key: "images",
    render: (text, row) => (
      <Image.PreviewGroup items={row.images}>
        <Image width={75} title="View" src={row.images[0]} />
      </Image.PreviewGroup>
    ),
  },
  {
    title: "Chapter heading",
    dataIndex: "chapter_headings",
    key: "chapter_headings",
    render: (text, row) => row?.chapter_headings?.name,
  },
  {
    title: "Bill No",
    dataIndex: "bill_no",
    key: "bill_no",
    render: (text, row) => <>{row.bill_no}</>,
  },
  {
    title: "Bill No & Date",
    dataIndex: "bill_date",
    key: "bill_date",
    render: (text, row) => moment(text).format("DD-MM-YYYY"),
  },
  {
    title: "Discrepancy Found",
    dataIndex: "discrepancy_found",
    key: "discrepancy_found",
    render: (text) =>
      text ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>,
  },
  {
    title: "Date of examination",
    dataIndex: "date_of_examination",
    key: "date_of_examination",
    render: (text) => (text ? moment(text).format("DD-MM-YYYY") : "N/a"),
  },
];

const ChapterWiseImage = () => {
  const [chapterHeading, setChapterHeading] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [params, setParams] = useState({});

  const getExaminedImage = useCallback(() => {
    const newParams = Object.assign(params, {
      chapter_headings: chapterHeading,
    });
    if (chapterHeading) {
      setLoading(true);
      EXAMINEDIMG_API.getExaminedImage(newParams)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [chapterHeading, params]);

  useEffect(() => {
    getExaminedImage();
  }, [getExaminedImage, params]);
  return (
    <>
      <Card title="Search By Chapter Wise Images" bordered={false}>
        <Space size="large" direction="vertical" style={{ width: "100%" }}>
          <Row gutter={18}>
            <Col sm={20}>
              <ChapeterHeadingList setData={setChapterHeading} />
            </Col>
            <Col sm={4}>
              <Button
                block
                type="default"
                ghost
                danger
                onClick={() => {
                  setChapterHeading(null);
                  setParams({});
                  setData({});
                }}
              >
                Reset
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={data.results}
            columns={columns}
            loading={loading}
            rowKey="id"
            bordered={true}
            pagination={{
              current: data.page,
              pageSize: data.limit,
              total: data.totalresults,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              onChange: (value) => {
                setParams({ ...params, page: value });
              },
            }}
          />
        </Space>
      </Card>
    </>
  );
};

export default ChapterWiseImage;
