import {
  Card,
  Form,
  Select,
  Input,
  Row,
  Col,
  DatePicker,
  message,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import { CORE_API, REPORT_API } from "../../apis/api";
import { useNavigate } from "react-router";
import dayjs from "dayjs";
const CreateDailyReport = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [scanners, setScanners] = useState([]);

  const save = (values) => {
    values.date = dayjs(values.date).format("YYYY-MM-DD");
    console.log(values);
    setLoading(true);
    REPORT_API.createDailyReport(values).then((res) => {
      message.success("Daily report created successfully");
      form.resetFields();
      navigate("/reports/daily-reports");
      setLoading(false);
    });
  };

  const getScanners = () => {
    CORE_API.getCoreData("scanners").then((response) => {
      setScanners(response.data);
    });
  };

  useEffect(() => {
    getScanners();
  }, []);

  return (
    <div>
      <Card title="Create Daily Report">
        <Form form={form} layout="vertical" onFinish={save}>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Form.Item name="date" label="Date" rules={[{ required: true }]}>
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="shift"
                label="Shift"
                rules={[{ required: true }]}
              >
                <Select>
                  <Select.Option value="day">Day</Select.Option>
                  <Select.Option value="night">Night</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="scanner_type"
                label="Scanner Type"
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Scanner type"
                  onChange={(value) => {
                    console.log("value", value);
                  }}
                >
                  {scanners.map((scanner) => (
                    <Select.Option
                      key={scanner._id}
                      value={`${scanner.name}(${scanner.code})`}
                    >
                      {scanner.name} ({scanner.code})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_reported"
                label="Total Reported"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_scanned"
                label="Total Scanned"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="not_scanned_20_ft"
                label="Not Scanned 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="not_scanned_40_ft"
                label="Not Scanned 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="scanned_20_ft"
                label="Scanned 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="scanned_40_ft"
                label="Scanned 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_scanned_containers"
                label="Total Scanned Containers"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_suspicious"
                label="Total Suspicious"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="suspicious_20_ft"
                label="Suspicious 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="suspicious_40_ft"
                label="Suspicious 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_dpd"
                label="Total DPD"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="dpd_20_ft"
                label="DPD 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="dpd_40_ft"
                label="DPD 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_odc"
                label="Total ODC"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="odc_20_ft"
                label="ODC 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="odc_40_ft"
                label="ODC 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_export"
                label="Total Export"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="export_20_ft"
                label="Export 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="export_40_ft"
                label="Export 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_icd_mod"
                label="Total ICD MOD"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="icd_mod_20_ft"
                label="ICD MOD 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="icd_mod_40_ft"
                label="ICD MOD 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_suspicious_icd"
                label="Total Suspicious ICD"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="suspicious_icd_mod_20_ft"
                label="Suspicious ICD MOD 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="suspicious_icd_mod_40_ft"
                label="Suspicious ICD MOD 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="total_suspicious_export"
                label="Total Suspicious Export"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="suspicious_export_20_ft"
                label="Suspicious Export 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="suspicious_export_40_ft"
                label="Suspicious Export 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="clean_gen_20_ft"
                label="Clean Gen 20 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="clean_gen_40_ft"
                label="Clean Gen 40 Ft"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="clean_icd_mod_20"
                label="Clean ICD MOD 20"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="clean_icd_mod_40"
                label="Clean ICD MOD 40"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={24}>
              <Button type="primary" block htmlType="submit" loading={loading}>
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
};
export default CreateDailyReport;
