import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { AUTH_API } from "../apis/api";
import { useNavigate } from "react-router";

const useAuth = () => {
  const loggedInUser = Cookies.get("user")
    ? JSON.parse(Cookies.get("user"))
    : undefined;
  const [user, setUser] = useState(loggedInUser || null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user = Cookies.get("user");
    if (user) {
      setUser(JSON.parse(user));
    }
  }, []);

  const login = async (username, password) => {
    setLoading(true);
    try {
      const response = await AUTH_API.login({ username, password });
      if (!response.data) {
        throw new Error("Login failed");
      }
      const authToken = response?.data?.tokens?.access?.token;
      Cookies.set("authToken", authToken);
      Cookies.set("user", JSON.stringify(response?.data?.user));
      setUser(response?.data?.user);
      setLoading(false);
      navigate(0);
    } catch (error) {
      console.error("Login error:", error);
      setLoading(false);
    }
  };

  const logout = () => {
    Cookies.remove("authToken");
    Cookies.remove("user");
    setUser(null);
    navigate("/");
  };
  return { user, login, logout, loading, setUser };
};

export default useAuth;
