import { Column } from "@ant-design/plots";
import React, { useEffect } from "react";
import { REPORT_API } from "../../apis/api";
import { Card } from "antd";

const CleanMismatchReport = () => {
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getData = () => {
    REPORT_API.getCleanMismatchReport().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const config = {
    data,
    xField: "month_name",
    yField: "value",
    colorField: "type",
    group: true,
    style: {
      inset: 5,
    },
  };

  return (
    <Card
      loading={loading}
      title={`Month wise clean & mismatch report (${new Date().getFullYear()})`}
    >
      <Column {...config} />
    </Card>
  );
};

export default CleanMismatchReport;
