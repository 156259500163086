import { Card, Col, Image, Input, Row, Table, Typography } from "antd";
import React, { useState } from "react";
import CONTAINER_API from "../../apis/containerAPI";
import { Link } from "react-router-dom";

const columns = [
  {
    title: "Container number",
    dataIndex: "container_number",
    key: "container_number",
    width: 200,
    render: (text, row) =>
      row.container_number ? (
        <Link
          target="_blank"
          to={`/container-management/containers/${row?.id}`}
        >
          <Typography.Link>{row?.container_number || "N/a"}</Typography.Link>
        </Link>
      ) : (
        "N/a"
      ),
  },

  {
    title: "CFS Name",
    dataIndex: "cfs_name",
    key: "cfs_name",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },

  {
    title: "Examined Images",
    dataIndex: "examined_images",
    key: "examined_images",
    render: (text, row) => (
      <Image.PreviewGroup items={row?.examinedImages?.[0]?.images}>
        <Image
          width={75}
          title="View"
          src={row?.examinedImages?.[0]?.images[0]}
        />
      </Image.PreviewGroup>
    ),
  },
];

const AISearchImage = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const onSearch = (value, _e, info) => {
    setLoading(true);
    CONTAINER_API.searchbyDesc(value).then((res) => {
      setLoading(false);
      setData(res.data);
    });
  };
  console.log("data", data);
  return (
    <>
      <Row gutter={[18, 18]}>
        <Col sm={24}>
          <Input.Search
            placeholder="Search images by description"
            loading={loading}
            enterButton="AI Search"
            size="large"
            onSearch={onSearch}
          />
        </Col>
        <Col sm={24}>
          <Card bodyStyle={{ padding: 0 }}>
            <Table columns={columns} dataSource={data} loading={loading} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default AISearchImage;
