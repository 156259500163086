import { cloneElement } from "react";
import { useAppContext } from "../../context/AppContext";
import useAuth from "../../hooks/useAuth";

export const hasPermission = (permissions, scopes) => {
  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({
  children,
  RenderError = () => <></>,
  errorProps = null,
  scopes = [],
}) {
  const { userPermissions } = useAppContext();
  const { user } = useAuth();
  const isAdmin =
    user?.role?.name === "admin" || user?.role?.name === "ADMIN" ? true : false;
  const permissionGranted = isAdmin
    ? true
    : hasPermission(userPermissions, scopes);

  if (!permissionGranted && !errorProps) return <RenderError />;

  if (!permissionGranted && errorProps)
    return cloneElement(children, { ...errorProps });

  return <>{children}</>;
}
