import {
  Button,
  Card,
  Drawer,
  Form,
  Input,
  Select,
  Space,
  Table,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { CORE_API, USER_API } from "../../apis/api";
import useAuth from "../../hooks/useAuth";
import PermissionsGate from "../../services/RBAC/permissionGate";
const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resetting, setResetting] = useState(false);
  const [params, setParams] = useState({});
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();
  const { user } = useAuth();

  const getUsers = useCallback(() => {
    setLoading(true);
    USER_API.getUsers(params)
      .then((response) => {
        setUsers(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const getRoles = () => {
    CORE_API.getCoreData("roles").then((res) => {
      setRoles(res.data);
    });
  };

  const adduser = () => {
    const data = form.getFieldsValue();
    setSubmitting(true);
    USER_API.createUser(data)
      .then((res) => {
        form.resetFields();
        setOpen(false);
        setSubmitting(false);
        message.success(`User ${res.data.name} aded successfully`);
        getUsers();
      })
      .catch((err) => message.error("Something went wrong"));
  };

  const toggleStatus = (id, status) => {
    USER_API.updateUser(status, id)
      .then((res) => {
        getUsers();
      })
      .catch((err) => message.error("Something went wrong"));
  };

  const resetPassword = (id) => {
    setResetting(true);
    USER_API.resetPassword(id)
      .then((res) => {
        setResetting(false);
        message.success("Password reset successfully done");
      })
      .catch((err) => {
        message.error("Something went wrong");
        setResetting(false);
      });
  };

  const columns = [
    {
      title: "SSO ID",
      dataIndex: "employee_id",
      key: "employee_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "name",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, row) => row?.role?.name,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) =>
        text ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">In-Active</Tag>
        ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (text, row) => {
        return (
          <Space>
            {row.status ? (
              <PermissionsGate
                scopes={["update-user"]}
                errorProps={{ disabled: true }}
              >
                <Button
                  size="small"
                  disabled={user.id === row.id}
                  ghost
                  danger
                  onClick={() => toggleStatus(row.id, false)}
                >
                  {" "}
                  Mark Inactive
                </Button>
              </PermissionsGate>
            ) : (
              <PermissionsGate
                scopes={["update-user"]}
                errorProps={{ disabled: true }}
              >
                <Button
                  disabled={user.id === row.id}
                  size="small"
                  ghost
                  type="primary"
                  onClick={() => toggleStatus(row.id, true)}
                >
                  Mark Active{" "}
                </Button>
              </PermissionsGate>
            )}

            <PermissionsGate
              scopes={["update-user"]}
              errorProps={{ disabled: true }}
            >
              <Button
                disabled={user.id === row.id}
                size="small"
                type="default"
                loading={resetting}
                onClick={() => resetPassword(row.id)}
              >
                Reset Password
              </Button>
            </PermissionsGate>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    getUsers();
    getRoles();
  }, [getUsers, params]);

  return (
    <>
      <Card
        title="Users List"
        bodyStyle={{ padding: 0 }}
        extra={
          <PermissionsGate scopes={["create-user"]}>
            <Button onClick={() => setOpen(true)}>Add user</Button>
          </PermissionsGate>
        }
      >
        <Table
          columns={columns}
          dataSource={users.results}
          loading={loading}
          rowKey="id"
          pagination={{
            current: users.page,
            pageSize: users.limit,
            total: users.totalResults,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: (value) => {
              setParams({ ...params, page: value });
            },
          }}
        />
      </Card>
      <Drawer
        title="Create User"
        width={500}
        onClose={() => setOpen(false)}
        open={open}
        maskClosable={false}
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical" onFinish={adduser}>
          <Form.Item
            label="SSO ID"
            name="employee_id"
            rules={[{ required: true, message: "SSO ID is required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true }, { type: "email" }]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Mobile"
            name="mobile"
            rules={[{ required: true }, { max: 10 }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="role" label="Role" rules={[{ required: true }]}>
            <Select placeholder="Select roles">
              {roles.map((role) => (
                <Select.Option key={role.id} value={role.id}>
                  {role.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Designation"
            name="designation"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Button block type="primary" htmlType="submit" loading={submitting}>
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export default Users;
