import axios from "./axios";

const IGM_API = {
  getIgmLogs: (params) =>
    axios({
      url: `/containers/igm-logs`,
      method: "GET",
      params,
    }),
};

export default IGM_API;
