import {
  Col,
  Row,
  Card,
  Image,
  Form,
  Input,
  Button,
  Typography,
  Alert,
  Space,
} from "antd";
import React, { useState } from "react";
import FooterLogo from "../../assets/images/footer-logo.png";
import CONTAINER_API from "../../apis/containerAPI";
import moment from "moment";
import { useNavigate } from "react-router";
import { LoginOutlined } from "@ant-design/icons";

const ContainerStatus = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const checkStatus = (values) => {
    values.igm_date = moment(values.igm_date).format("YYYY-M-D");
    console.log("Received values of form:", values);
    setLoading(true);
    CONTAINER_API.checkStatus(values)
      .then((res) => {
        console.log(res);
        if (res.data) {
          setData(res.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response?.data?.message ?? error.message);
      });
  };

  return (
    <Row
      style={{
        minHeight: "87vh",
        background: "#093461",

        padding: "50px 30px",
      }}
    >
      <Col sm={24} xs={24} md={24}>
        <Image width={200} src={FooterLogo} preview={false} />
        <Card
          title="Check Container Status"
          style={{ marginTop: 50 }}
          bodyStyle={{ minHeight: "50vh" }}
          extra={
            <Typography.Link strong onClick={() => navigate("/")}>
              <LoginOutlined /> Login
            </Typography.Link>
          }
        >
          <Form name={form} layout="vertical" onFinish={checkStatus}>
            <Row gutter={32}>
              <Col sm={6} xs={24}>
                <Form.Item
                  label="Container Number"
                  name="container_number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your container number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={6} xs={24}>
                <Form.Item
                  label="IGM Number"
                  name="igm_number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your igm number!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col sm={6} xs={24}>
                <Form.Item
                  label="IGM Date"
                  name="igm_date"
                  rules={[
                    {
                      required: true,
                      message: "Please input your IGM date!",
                    },
                  ]}
                >
                  <Input type="date" />
                </Form.Item>
              </Col>
              <Col sm={6} xs={24}>
                <Form.Item label=" ">
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    loading={loading}
                  >
                    Check Status
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {data?.container_number && (
            <Space direction="vertical" style={{ marginTop: 50 }} size="middle">
              <Typography.Text strong>
                Container Number:{" "}
                <Typography.Text mark>{data?.container_number}</Typography.Text>
              </Typography.Text>
              <Alert
                type={data.is_scanned_image_uploaded ? "success" : "error"}
                message={
                  data.is_scanned_image_uploaded
                    ? "Scanned image is  uploaded"
                    : "Scanned image is not uploaded"
                }
              />

              <Alert
                type={data.is_examined_image_uploaded ? "success" : "error"}
                message={
                  data.is_scanned_image_uploaded
                    ? "Examined images are  uploaded"
                    : "Examined images are  not uploaded"
                }
              />
            </Space>
          )}
        </Card>
      </Col>
    </Row>
  );
};
export default ContainerStatus;
