import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Table,
  Space,
  Typography,
  Tag,
  Image,
  Button,
  message,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import SCANNEDIMG_API from "../../apis/scannedImgAPI";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

const SpecialWatch = () => {
  const [scannedImage, setScannedImage] = useState({});
  const [params, setParams] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const getScannedImage = useCallback(() => {
    setLoading(true);
    const newParams = Object.assign(params, {
      is_special_watch: true,
    });
    console.log("newParams", newParams);
    SCANNEDIMG_API.getScannedImage(newParams)
      .then((response) => {
        setScannedImage(response.data);
        setError(false);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const toggleMark = (id, value) => {
    SCANNEDIMG_API.toggleMark(id, value).then((response) => {
      message.success(
        value
          ? "Container marked for special watch successfully"
          : "Container un-marked for special watch successfully"
      );
      getScannedImage();
    });
  };

  const columns = [
    {
      title: "Scanned Image",
      dataIndex: "images",
      key: "images",
      render: (text, row) => (
        <Image.PreviewGroup items={row.images}>
          <Image width={75} title="View" src={row.images[0]} />
        </Image.PreviewGroup>
      ),
    },
    {
      title: "Container number",
      dataIndex: "container_number",
      key: "container_number",
      render: (text, row) =>
        row.container_number ? (
          <Link
            to={`/container-management/containers/${row?.container_number?._id}`}
          >
            <Typography.Link>
              {row?.container_number?.container_number || "N/a"}
            </Typography.Link>
          </Link>
        ) : (
          "N/a"
        ),
    },

    {
      title: "IGM Number",
      dataIndex: "igm_number",
      key: "igm_number",
      render: (text, row) => row?.container_number?.igm_number || "N/a",
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      render: (text, row) => row?.shift,
    },
    {
      title: "Scanner Type",
      dataIndex: "scanner_type",
      key: "scanner_type",
      render: (text, row) => row?.scanner_type,
    },
    {
      title: "Special Watch",
      dataIndex: "is_special_watch",
      key: "is_special_watch",
      render: (text) =>
        text ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>,
    },
    {
      title: "Not scanned",
      dataIndex: "is_not_scanned",
      key: "is_not_scanned",
      render: (text) =>
        text ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>,
    },
    {
      title: "Examined images",
      dataIndex: "examined_images",
      key: "examined_images",
      render: (text) =>
        text.length > 0 ? (
          <Tag color="green"> uploaded</Tag>
        ) : (
          <Tag color="orange">Not uploaded</Tag>
        ),
    },

    {
      title: "Unmark",
      dataIndex: "is_special_watch",
      key: "is_special_watch",
      render: (text, row) =>
        !text ? (
          <Tooltip title="Click to Mark Special watch">
            <Button
              type="text"
              icon={<EyeOutlined />}
              onClick={() => toggleMark(row.id, true)}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Click to Unmark Special watch">
            <Button
              type="text"
              danger
              icon={<EyeInvisibleOutlined />}
              onClick={() => toggleMark(row._id, false)}
            />
          </Tooltip>
        ),
    },
  ];

  useEffect(() => {
    getScannedImage();
  }, [getScannedImage, params]);
  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Card title="Special watch" bodyStyle={{ padding: 0 }}>
          <Table
            dataSource={scannedImage.results}
            columns={columns}
            loading={loading}
            rowKey="id"
            pagination={{
              current: scannedImage.page,
              pageSize: scannedImage.limit,
              total: scannedImage.totalresults,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              onChange: (value) => {
                setParams({ ...params, page: value });
              },
            }}
          />
        </Card>
      </Space>
    </>
  );
};

export default SpecialWatch;
