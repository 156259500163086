import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Card,
  Table,
  Space,
  Typography,
  Tag,
  Image,
  Form,
  Drawer,
  Select,
  Spin,
  Input,
  DatePicker,
  Col,
  Row,
  Upload,
  Button,
  message,
} from "antd";
import { Link } from "react-router-dom";
import EXAMINEDIMG_API from "../../apis/examinedImgAPI";
import moment from "moment";
import debounce from "lodash/debounce";
import Cookies from "js-cookie";
import { ChapeterHeadingList } from "../containers";
import { UploadOutlined } from "@ant-design/icons";
import { jsonToFormData } from "../../utils";
import dayjs from "dayjs";
import PermissionsGate from "../../services/RBAC/permissionGate";

const columns = [
  {
    title: "Container number",
    dataIndex: "container_number",
    key: "container_number",
    render: (text, row) =>
      row.container_number ? (
        <Link
          to={`/container-management/containers/${row?.container_number?.id}`}
        >
          <Typography.Link>
            {row?.container_number?.container_number || "N/a"}
          </Typography.Link>
        </Link>
      ) : (
        "N/a"
      ),
  },

  {
    title: "Chapter heading",
    dataIndex: "chapter_headings",
    key: "chapter_headings",
    render: (text, row) => row?.chapter_headings?.name,
  },
  {
    title: "Bill No & Date",
    dataIndex: "bill_no",
    key: "bill_no",
    render: (text, row) => (
      <>
        {row.bill_no} ({moment(row.bill_date).format("DD-MM-YYYY")})
      </>
    ),
  },
  {
    title: "Discrepancy Found",
    dataIndex: "discrepancy_found",
    key: "discrepancy_found",
    render: (text) =>
      text ? <Tag color="red">Yes</Tag> : <Tag color="green">No</Tag>,
  },
  {
    title: "Date of examination",
    dataIndex: "date_of_examination",
    key: "date_of_examination",
    render: (text) => (text ? moment(text).format("DD-MM-YYYY") : "N/a"),
  },
  {
    title: "Images",
    dataIndex: "images",
    key: "images",
    render: (text, row) => (
      <Image.PreviewGroup items={row.images}>
        <Image width={75} title="View" src={row.images[0]} />
      </Image.PreviewGroup>
    ),
  },
  {
    title: "Uploaded by",
    dataIndex: "created_by",
    key: "created_by",
    render: (text, row) => (
      <span>
        {text?.name} ({text?.employee_id})
      </span>
    ),
  },
];
const ExaminedImages = (props) => {
  const [examinedImage, setExaminedImage] = useState({});
  const [fileList, setFileList] = useState([]);
  const [chapterHeading, setChapterHeading] = useState(null);
  const [params, setParams] = useState({});
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [discrepancyFound, setDiscrepancyFound] = useState("N");
  const [form] = Form.useForm();
  const imageprops = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  const getExaminedImage = useCallback(() => {
    setLoading(true);
    const newParams = Object.assign(params, {
      container_number: props.container_id,
    });
    EXAMINEDIMG_API.getExaminedImage(newParams)
      .then((response) => {
        setExaminedImage(response.data);
        setError(false);
      })
      .catch((error) => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const save = (values) => {
    if (fileList.length < 4) {
      message.error("Please upload at least 4 images");
      return;
    }
    setSubmitting(true);
    values.date_of_examination = values.date_of_examination
      ? `${dayjs(values.date_of_examination).format("YYYY-M-D")}`
      : undefined;
    values.bill_date = values.bill_date
      ? `${dayjs(values.bill_date).format("YYYY-M-D")}`
      : undefined;
    console.log("values", values);
    const formData = jsonToFormData(values);
    fileList.forEach((file) => {
      formData.append("examined_images", file);
    });
    formData.append("chapter_headings", chapterHeading);
    EXAMINEDIMG_API.createExaminedImage(formData)
      .then((response) => {
        console.log("res", response);
        if (response.data) {
          message.success("Examined images uploaded sucessfully");
          setFileList([]);
          form.resetFields();
          getExaminedImage();
          setOpen(false);
          setDiscrepancyFound(false);
          setChapterHeading(null);
        }
      })
      .catch((error) => {
        message.error("Something went wrong");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    getExaminedImage();
  }, [getExaminedImage, params]);
  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }} size="middle">
        <Card
          title="Examined Images"
          bodyStyle={{ padding: 0 }}
          extra={
            <PermissionsGate scopes={["create-examined-images"]}>
              <Button type="default" onClick={() => setOpen(true)}>
                Add
              </Button>
            </PermissionsGate>
          }
        >
          <Table
            dataSource={examinedImage.results}
            columns={columns}
            loading={loading}
            rowKey="id"
            pagination={{
              current: examinedImage.page,
              pageSize: examinedImage.limit,
              total: examinedImage.totalResults,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              onChange: (value) => {
                console.log("value", value);
                setParams({ ...params, page: value });
              },
            }}
          />
        </Card>
      </Space>
      <Drawer
        title="Upload examined images"
        destroyOnClose={true}
        maskClosable={false}
        width={900}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Form form={form} layout="vertical" onFinish={save}>
          <Form.Item
            name="container_number"
            label="Search Container by IGM  No or container number"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <ContainerAutoComplete
              placeholder="Search container by IGM no/ container number"
              fetchOptions={fetchData}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
          <Form.Item label="Chapter heading">
            <ChapeterHeadingList setData={setChapterHeading} />
          </Form.Item>

          <Row gutter={32}>
            <Col sm={8}>
              <Form.Item
                name="bill_no"
                label="Bill Number"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item name="bill_date" label="Bill date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col sm={8}>
              <Form.Item name="date_of_examination" label="Examination date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="description"
            label="IGM Description"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="examination_findings"
            label="Examination findings"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="discrepancy_found"
            label=" Is Discrepancy found"
            rules={[{ required: true }]}
            extra="This will automatically create a case for the examination finding for this container"
          >
            <Select
              placeholder="Is discrepancy found"
              value={discrepancyFound}
              onChange={(value) => {
                console.log("value", value);
                setDiscrepancyFound(value);
              }}
            >
              <Select.Option value="N"> No</Select.Option>
              <Select.Option value="Y"> Yes</Select.Option>
            </Select>
          </Form.Item>
          {discrepancyFound === "Y" && (
            <>
              <Form.Item name="importer_name" label="Importer name">
                <Input />
              </Form.Item>
              <Form.Item name="additional_revenue" label="Additional_Revenue">
                <Input />
              </Form.Item>
              <Form.Item
                name="scanning_result"
                label=" Scanning result"
                rules={[{ required: true }]}
              >
                <Select placeholder="scanning result">
                  <Select.Option value={"clean"}> Clean</Select.Option>
                  <Select.Option value={"mismatch"}> Mismatch</Select.Option>
                </Select>
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Select examined images"
            rules={[{ required: true }]}
            required
            help="Please select at least 4 image"
          >
            <Upload
              {...imageprops}
              accept="image/png, image/jpeg"
              listType="picture"
              multiple
            >
              <Button type="link" icon={<UploadOutlined />}>
                Select Files
              </Button>
            </Upload>
          </Form.Item>

          <Button
            style={{ marginTop: 20 }}
            type="primary"
            htmlType="submit"
            block
            loading={submitting}
          >
            Submit
          </Button>
        </Form>
      </Drawer>
    </>
  );
};

export const ContainerAutoComplete = ({
  fetchOptions,
  debounceTimeout = 300,
  ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      showSearch
      allowClear
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
};

const fetchData = async (keyword) => {
  return fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/containers/search-for-examined?keyword=${keyword}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
    }
  )
    .then((response) => response.json())
    .then((body) => {
      return body.map((item) => ({
        label: (
          <Typography.Text>
            {item.container_number.container_number} -
            {item.container_number.igm_number}{" "}
            <Tag color="yellow">Not scanned</Tag>
          </Typography.Text>
        ),
        value: item.container_number.id,
      }));
    });
};

export default ExaminedImages;
