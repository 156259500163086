import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import CONTAINER_API from "../../apis/containerAPI";
import { Card, Descriptions, Skeleton, Tabs, Tag } from "antd";
import moment from "moment";
import ScannedImages from "../scannedImages";
import ExaminedImages from "../examinedImages";

const ContainerDetails = () => {
  const [container, setContainer] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const { id } = params;

  const getContainer = () => {
    setLoading(true);
    CONTAINER_API.getContainer(id).then((res) => {
      setContainer(res.data);
      setLoading(false);
    });
  };
  const items = [
    {
      key: "details",
      label: "Container Details",
      children: <DetailsTab data={container} loading={loading} />,
    },
    {
      key: "scanned",
      label: "Scanned Images",
      children: <ScannedImages container_id={id} />,
    },
    {
      key: "examined",
      label: "Examined Images",
      children: <ExaminedImages container_id={id} />,
    },
  ];

  useEffect(() => {
    getContainer();
  }, []);

  return (
    <Card
      bordered={false}
      loading={loading}
      title={<Tag color="blue">{container?.container_number}</Tag>}
    >
      <Tabs items={items} defaultActiveKey="details"></Tabs>
    </Card>
  );
};

const DetailsTab = ({ data, loading }) => {
  return loading ? (
    <Skeleton />
  ) : (
    <>
      <Descriptions title="Container Info" column={3}>
        <Descriptions.Item label="Container number">
          {data?.container_number}
        </Descriptions.Item>
        <Descriptions.Item label="IGM Number">
          {data?.igm_number}
        </Descriptions.Item>
        <Descriptions.Item label="IGM Date">
          {moment(data?.igm_date).format("DD-MM-YYYY")}
        </Descriptions.Item>
        <Descriptions.Item label="Port">{data?.port?.name}</Descriptions.Item>
        <Descriptions.Item label="Description">
          {data?.description}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="CFS" column={2}>
        <Descriptions.Item label="CFS ID">{data?.cfs_name}</Descriptions.Item>
        <Descriptions.Item label="CFS name">
          {data?.cfs?.cfs_name}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default ContainerDetails;
