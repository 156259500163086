import React, { useCallback, useEffect, useState } from "react";
import { REPORT_API } from "../../apis/api";
import { Button, Card, message, Space, Table } from "antd";
import { useNavigate } from "react-router";
import dayjs from "dayjs";

const DailyReport = () => {
  const [params, setParams] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getData = useCallback(() => {
    setLoading(true);
    REPORT_API.getDailyReport(params).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  }, [params]);

  const deleteDailyReport = (id) => {
    REPORT_API.deleteDailyReport(id).then(() => {
      message.success("Daily report deleted successfully");
      getData();
    });
  };

  useEffect(() => {
    getData();
  }, [getData, params]);

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: "left",
      render: (text) => dayjs(text).format("DD-MM-YYYY"),
    },
    {
      title: "Shift",
      dataIndex: "shift",
      key: "shift",
      fixed: "left",
    },
    {
      title: "Total Reported",
      dataIndex: "total_reported",
      key: "total_reported",
    },
    {
      title: "Total Scanned",
      dataIndex: "total_scanned",
      key: "total_scanned",
    },
    {
      title: "Not Scanned 20 Ft",
      dataIndex: "not_scanned_20_ft",
      key: "not_scanned_20_ft",
    },
    {
      title: "Not Scanned 40 Ft",
      dataIndex: "not_scanned_40_ft",
      key: "not_scanned_40_ft",
    },
    {
      title: "Scanned 20 Ft",
      dataIndex: "scanned_20_ft",
      key: "scanned_20_ft",
    },
    {
      title: "Scanned 40 Ft",
      dataIndex: "scanned_40_ft",
      key: "scanned_40_ft",
    },
    {
      title: "Total Scanned Containers",
      dataIndex: "total_scanned_containers",
      key: "total_scanned_containers",
    },
    {
      title: "Total Suspicious",
      dataIndex: "total_suspicious",
      key: "total_suspicious",
    },
    {
      title: "Suspicious 20 Ft",
      dataIndex: "suspicious_20_ft",
      key: "suspicious_20_ft",
    },
    {
      title: "Suspicious 40 Ft",
      dataIndex: "suspicious_40_ft",
      key: "suspicious_40_ft",
    },
    {
      title: "Total DPD",
      dataIndex: "total_dpd",
      key: "total_dpd",
    },
    {
      title: "DPD 20 Ft",
      dataIndex: "dpd_20_ft",
      key: "dpd_20_ft",
    },
    {
      title: "DPD 40 Ft",
      dataIndex: "dpd_40_ft",
      key: "dpd_40_ft",
    },
    {
      title: "Total ODC",
      dataIndex: "total_odc",
      key: "total_odc",
    },
    {
      title: "ODC 20 Ft",
      dataIndex: "odc_20_ft",
      key: "odc_20_ft",
    },
    {
      title: "ODC 40 Ft",
      dataIndex: "odc_40_ft",
      key: "odc_40_ft",
    },
    {
      title: "Total Export",
      dataIndex: "total_export",
      key: "total_export",
    },
    {
      title: "Export 20 Ft",
      dataIndex: "export_20_ft",
      key: "export_20_ft",
    },
    {
      title: "Export 40 Ft",
      dataIndex: "export_40_ft",
      key: "export_40_ft",
    },
    {
      title: "Total ICD MOD",
      dataIndex: "total_icd_mod",
      key: "total_icd_mod",
    },
    {
      title: "ICD MOD 20 Ft",
      dataIndex: "icd_mod_20_ft",
      key: "icd_mod_20_ft",
    },
    {
      title: "ICD MOD 40 Ft",
      dataIndex: "icd_mod_40_ft",
      key: "icd_mod_40_ft",
    },
    {
      title: "Total Suspicious ICD",
      dataIndex: "total_suspicious_icd",
      key: "total_suspicious_icd",
    },
    {
      title: "Suspicious ICD MOD 20 Ft",
      dataIndex: "suspicious_icd_mod_20_ft",
      key: "suspicious_icd_mod_20_ft",
    },
    {
      title: "Suspicious ICD MOD 40 Ft",
      dataIndex: "suspicious_icd_mod_40_ft",
      key: "suspicious_icd_mod_40_ft",
    },
    {
      title: "Total Suspicious Export",
      dataIndex: "total_suspicious_export",
      key: "total_suspicious_export",
    },
    {
      title: "Suspicious Export 20 Ft",
      dataIndex: "suspicious_export_20_ft",
      key: "suspicious_export_20_ft",
    },
    {
      title: "Suspicious Export 40 Ft",
      dataIndex: "suspicious_export_40_ft",
      key: "suspicious_export_40_ft",
    },
    {
      title: "Clean Gen 20 Ft",
      dataIndex: "clean_gen_20_ft",
      key: "clean_gen_20_ft",
    },
    {
      title: "Clean Gen 40 Ft",
      dataIndex: "clean_gen_40_ft",
      key: "clean_gen_40_ft",
    },
    {
      title: "Clean ICD MOD 20 Ft",
      dataIndex: "clean_icd_mod_20",
      key: "clean_icd_mod_20",
    },
    {
      title: "Clean ICD MOD 40 Ft",
      dataIndex: "clean_icd_mod_40",
      key: "clean_icd_mod_40",
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      key: "created_by",
      fixed: "right",
      width: 100,
      render: (text, row) => <span>{row.created_by.name}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (text, row) => (
        <Space>
          <Button type="text" danger onClick={() => deleteDailyReport(row.id)}>
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Card
        title="Daily reports"
        bodyStyle={{ padding: 0 }}
        extra={
          <Button onClick={() => navigate("/reports/daily-reports/create")}>
            Create Daily Report
          </Button>
        }
      >
        <Table
          bordered={true}
          loading={loading}
          dataSource={data.results || []}
          columns={columns}
          rowKey={(row) => row.id}
          scroll={{
            x: 4000,
          }}
          pagination={{
            current: data.page,
            pageSize: data.limit,
            total: data.totalresults,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            onChange: (value) => {
              setParams({ ...params, page: value });
            },
          }}
        />
      </Card>
    </div>
  );
};

export default DailyReport;
