import React, { useEffect, useState } from "react";
import { CORE_API } from "../../apis/api";
import { Card, Col, Row, Statistic } from "antd";
import MonthlyScannedReport from "./MonthlyScannedReport";
import CleanMismatchReport from "./CleanMismatchReport";
const Dashboard = () => {
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);

  const getStats = () => {
    setLoading(true);
    CORE_API.getStats().then((res) => {
      setStats(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={6}>
        <Card bordered={false} loading={loading}>
          <Statistic
            title="Total containers"
            value={stats.total_containers || 0}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={false} loading={loading}>
          <Statistic
            title="Total Scanned Containers"
            value={stats.total_scanned || 0}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={false} loading={loading}>
          <Statistic
            title="Total Examined Containers"
            value={stats.total_examined || 0}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card bordered={false} loading={loading}>
          <Statistic title="Total cases" value={stats.total_cases || 0} />
        </Card>
      </Col>

      <Col span={12}>
        <MonthlyScannedReport />
      </Col>
      <Col span={12}>
        <CleanMismatchReport />
      </Col>
    </Row>
  );
};

export default Dashboard;
