import React, { useEffect, useState } from "react";
import { USER_API } from "../apis/api";
import Cookies from "js-cookie";
import { Card, Descriptions } from "antd";
const Profile = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(Cookies.get("user"));
  const getUser = () => {
    setLoading(true);
    USER_API.getUser(user.id).then((response) => {
      setData(response.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Card loading={loading}>
      <Descriptions title="Profile" column={3}>
        <Descriptions.Item label="Name">{data?.name}</Descriptions.Item>
        <Descriptions.Item label="Email">{data?.email}</Descriptions.Item>
        <Descriptions.Item label="Email">{data?.mobile}</Descriptions.Item>
        <Descriptions.Item label="Designation">
          {data?.designation}
        </Descriptions.Item>
        <Descriptions.Item label="Role">{data?.role?.name}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
};
export default Profile;
